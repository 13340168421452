.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

* {
  box-sizing: border-box;
}
body {
  background: hsl(20, 10%, 94%);
  padding: 24px;
  text-align: center;
  font-family: monospace;
}
h1 {
  max-width: 450px;
  margin: auto;
  padding-bottom: 1em;
}
button {
  margin-left: 2px;
  margin-right: 2px;
}
input {
  text-align: center;
}
section {
  max-width: 450px;
  margin: 28px auto;
  padding: 8px;
  /* border: 1px dashed red; */
}
section,
.twitter-component,
.newsletter-component {
  max-width: 450px;
  margin: 28px auto;
  padding: 8px;
  /* border: 1px dashed red; */
}
.twitter-component,
.newsletter-component {
  width: 70%;
}
.leadin {
  text-align: justify;
  max-width: 450px;
  margin: auto auto 24px auto;
  font-size: 13px;
  font-family: sans-serif;
  line-height: 1.4em;
  padding: 2em;
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
}
.complabel-component {
  display: none;
  transform: translateY(-18px) translateX(-100px);
  background-color: whitesmoke;
  padding: 1px 3px;
  border: 1px solid gainsboro;
}
.highlightjs-component {
  padding-top: 14px;
  border-top: 1px solid gainsboro;
  max-width: 450px;
  margin: 24px auto;
  text-align: left;
}

.bg-white-400 {
  background-color: white;
}

.bg-green {
  background-color: #64B12D;
}

.text-pink {
  color: #DD007F;
}


.text-locked {
  color: #434343
}